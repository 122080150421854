import * as React from "react";

import bgImage from "@images/error/bg-error.3x.png";
import card1 from "@images/error/error-cart-1.3x.png";
import card2 from "@images/error/error-cart-2.3x.png";
import card3 from "@images/error/error-cart-3.3x.png";
import { Link } from "gatsby";

import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@components/common/button";
import { IconTypeEnum } from "@components/common/icon";
import { CATALOG_PAGE_NAME } from "@components/constants";

import "./404.scss";

const NotFoundPage = () =>
  (
    <div className="error">
      <div className="error__wrapper">
        <div className="over-img">
          <img className="error__bg-image" src={bgImage} alt="Lune" />
        </div>
        <div className="page-width">
          <div className="error__inner">
            <div className="error__content">
              <div className="error__cards">
                <img src={card1} alt="" className="error__card-img" />
                <img src={card2} alt="" className="error__card-img" />
                <img src={card3} alt="" className="error__card-img" />
              </div>
              <h2 className="error__title">Такой страницы на сайте нет</h2>
              <p className="error__description">
                Но Фортуна вам улыбается
                <br />
                {" "}
                и приглашает выбрать эксперта
              </p>
              <div className="error__btn">
                <Link to="/">
                  <Button
                    text="Перейти на главную"
                    size={ButtonSizeEnum.Large}
                    color={ButtonColorEnum.Dark}
                    icon={IconTypeEnum.Right}
                    iconPosition={ButtonIconPositionEnum.Right}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

export default NotFoundPage;
